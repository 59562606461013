<template>
  <div class="iriets-home">
    <div class="top-swiper">
      <img class="top-bg" src="@/assets/index/index_bg.png" alt="">
      <div class="top-title wrapper">
        <span class="b-title">南京易锐思科技有限公司</span>
        <span class="sub-title">致力于为客户提供高效、创新、可靠的解决方案，帮助客户在竞争激烈的市场中保持领先地位。</span>
      </div>
    </div>
    <p class="title">核心技术</p>
    <div class="wrapper pb-80">
      <div class="content mb-50">
        <img class="l-img" src="@/assets/technology/1/10.png" alt="">
        <div class="r-desc">
          <p class="f26 t1 fbold">数字孪生</p>
          <p class="f16 t2">
            以数字孪生为理念，三维可视化为特点，深度融合IOT物联网、5G、大数据、云计算、AI等前沿技术应用，将信息、技术、设备与业务需求有机结合形成各类管理场景，为各类数据提供承载平台。</p>
          <div class="more" @click="goTo('technology/1')">
            查看详情
          </div>
        </div>
      </div>
      <div class="content mb-50">
        <img class="l-img" src="@/assets/index/nav_1_2.png" alt="">
        <div class="r-desc">
          <p class="f26 t1 fbold">数据治理</p>
          <p class="f16 t2">
            借助以有效的元数据和策略管理为支持的数据目录，激活用于AI和分析的业务就绪数据，实现对敏感数据的发现、标注、管理和隐私保护，为用户提供一站式的自助服务式的数据体验。</p>
          <div class="more" @click="goTo('technology/2')">
            查看详情
          </div>
        </div>
      </div>
      <div class="content mb-50">
        <img class="l-img" src="@/assets/index/nav_1_3.png" alt="">
        <div class="r-desc">
          <p class="f26 t1 fbold">智慧运维</p>
          <p class="f16 t2">
            以算法为支撑，数据为基础，场景为导向，将收集的海量数据进行集成、治理、多维分析，充分挖掘数据潜力，助力企业高效运行、快速决策。</p>
          <div class="more" @click="goTo('technology/3')">
            查看详情
          </div>
        </div>
      </div>
      <div class="content mb-50">
        <img class="l-img" src="@/assets/index/nav_1_4.png" alt="">
        <div class="r-desc">
          <p class="f26 t1 fbold">SaaS平台</p>
          <p class="f16 t2">
            深耕市场，在充分了解市场需求以及用户心理的情况下，为客户提供最全面，最完善的SaaS服务。助企业降低IT成本，让企业投入更有价值。</p>
          <div class="more" @click="goTo('technology/4')">
            查看详情
          </div>
        </div>
      </div>
    </div>
    <div class="jiuejue-fangan pb-80">
      <p class="title">解决方案</p>
      <div class="wrapper">
        <el-row :gutter="30" class="mb-20">
          <el-col :span="12">
            <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
              <div class="image-box" @click="goTo('product/8')">
                <img src="@/assets/index/jiejue1.png" class="image">
              </div>
              <div class="fangan-height" @click="goTo('product/8')">
                <div class="f18 t1 text-center">智能物联网平台</div>
                <div class="f14 t2">
                  易锐思智能物联网平台拥有强大的设备接入能力，可有效应对多种复杂场景下设备管理问题，同时支持双副本容灾，拥有秒级容灾切换能力。
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
              <div class="image-box" @click="goTo('product/10')">
                <img src="@/assets/index/jiejue2.png" class="image">
              </div>
              <div class="fangan-height" @click="goTo('product/10')">
                <div class="f18 t1 text-center">趣游园智慧景区SaaS管理平台</div>
                <div class="f14 t2">
                  趣游园是一个面向中小型休闲庄园、景区、公园的基于SaaS模式的智慧景区管理平台，贴合国家乡村振兴政略，以促进乡村旅游发展为使命。
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="30" class="mb-20">
          <el-col :span="12">
            <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
              <div class="image-box" @click="goTo('product/9')">
                <img src="@/assets/index/jiejue3.png" class="image">
              </div>
              <div class="fangan-height" @click="goTo('product/9')">
                <div class="f18 t1 text-center">易苍穹无人机调度平台</div>
                <div class="f14 t2">
                  易苍穹无人机调度平台与DJI开放平台深入对接，实现无人机自动导航、自动飞行控制等高级功能，可为应急、消防、安防等场景提供现场监测数据实时采集、现场画面实时回传、无人机自动巡检专业解决方案。
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
              <div class="image-box" @click="goTo('product/1')">
                <img src="@/assets/index/jiejue4.png" class="image">
              </div>
              <div class="fangan-height" @click="goTo('product/1')">
                <div class="f18 t1 text-center">SOM智能运维平台</div>
                <div class="f14 t2">
                  提供全面的网络设备性能监控，是具备监控、管理、分析和预测预警功能的新一代运维解决方案，实时保障业务安全稳定的运行。
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div v-if="loadMore">
          <el-row :gutter="30" class="mb-20">
            <el-col :span="12">
              <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
                <div class="image-box" @click="goTo('product/2')">
                  <img src="@/assets/index/jiejue5.png" class="image">
                </div>
                <div class="fangan-height" @click="goTo('product/2')">
                  <div class="f18 t1 text-center">DICT集中运维平台</div>
                  <div class="f14 t2">
                    DICT是一套性能稳定、功能强大的智能运维平台，集成了设备监控、自动化运维、日志分析、业务告警等多种功能，推动了运维工作从无序到有序、从被动到主动、从人工到智能的演进。
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
                <div class="image-box" @click="goTo('product/3')">
                  <img src="@/assets/index/jiejue6.png" class="image">
                </div>
                <div class="fangan-height" @click="goTo('product/3')">
                  <div class="f18 t1 text-center">智能审核平台</div>
                  <div class="f14 t2">
                    智能审核平台以文本、图片、视频、音频识别技术为核心，为各业务团队提供全方位的审核服务，实现“人工+智能”的审核目标，保障系统的内容安全。也致力于搭建健康的网络平台，为绿色网络保驾护航。
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="30" class="mb-20">
            <el-col :span="12">
              <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
                <div class="image-box" @click="goTo('product/11')">
                  <img src="@/assets/index/jiejue7.png" class="image">
                </div>
                <div class="fangan-height" @click="goTo('product/1')">
                  <div class="f18 t1 text-center">智慧物管系统</div>
                  <div class="f14 t2">
                    智慧物管系统利用物联网、云计算、移动互联网、智能终端等新一代信息技术，将智能物管、物业缴费、报事报修、安防监控等诸多服务整合为统一平台，以微信公众号、小程序、数字化安防设施等为基础，构建的智能化物业管理综合服务平台。
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card :body-style="{ padding: '0px' }" class="jiejue-box">
                <div class="image-box" @click="goTo('product/6')">
                  <img src="@/assets/index/jiejue8.png" class="image">
                </div>
                <div class="fangan-height" @click="goTo('product/6')">
                  <div class="f18 t1 text-center">标签信息管理系统</div>
                  <div class="f14 t2">
                    智能审核平台以文本、图片、视频、音频识别技术为核心，为各业务团队提供全方位的审核服务，实现“人工+智能”的审核目标，保障系统的内容安全。也致力于搭建健康的网络平台，为绿色网络保驾护航。
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="loadMore == false" class="load-more" @click="loadMore = true">加载更多 <i class="el-icon-arrow-down el-icon-arrow-color"></i></div>
      <div v-if="loadMore == true" class="load-more" @click="loadMore = false">收起 <i class="el-icon-arrow-up el-icon-arrow-color"></i></div>
    </div>
    <p class="title">劳务派遣</p>
    <div class="laowu wrapper pb-80">
      <div class="laowu-top flex">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="laowu-top-l">
              <p class="f18 t1">为企业解决 IT 人才需求，提供一站式、高效人才外包服务。</p>
              <p class="f14 t2">
                公司根据客户项目具体人员需求，将软件开发团队或个人驻场到客户现场进行软件开发，帮助客户及时完成项目，项目完成后这些IT开发团队或者个人会撤离客户公司，客户方只负责用人，软件外包员工的薪酬、培训、社保缴纳等都由外包公司负责。
                秉承人才、技术、管理有机结合的理念，根据用人需求，提供软件开发与测试人才，快速高效的响应机制，为客户提供全面的人力解决方案。</p>
            </div>
          </el-col>
          <el-col :span="12">
            <img src="@/assets/index/laowu.png" alt="">
          </el-col>
        </el-row>
      </div>
      <!-- <div class="laowu-top-btn">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-card class="box-card">
              <div class="flex alignCenter f18 t1-color mb-20">
                <img src="@/assets/index/laowu1.png" alt="" class="mr-10"> 10年行业经验
              </div>
              <div class="f16 t2-color t2">
                10年行业经验，拥有业内经验丰富的开发/设计工程师团队，服务范围包括定制软件开发、移动端开发、企业信息化建设。
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="box-card">
              <div class="flex alignCenter f18 t1-color mb-20">
                <img src="@/assets/index/laowu2.png" alt="" class="mr-10"> 技能覆盖全面
              </div>
              <div class="f16 t2-color t2">
                涵盖每个主流的行业技能：Java、C#、.NET、PHP、Android、IOS、Web前端、UI等。我们都有相应的专业人员为您服务。
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <div class="customers">
      <div class="wrapper">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="text-center">
              <p class="title">服务客户</p>
              <p class="c666 f20 mb-20"><span class="f40 fbold mr-10">10+</span>个省份</p>
              <p class="c666 f20 "><span class="f40 fbold mr-10">30+</span>个城市</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div v-if="!isMobile" class="viewmap" style="height: 100%;">
              <div id="china-echarts" style="width:100%;height:500px"></div>
            </div>
            <div v-else class="china-city">
              <img class="china-bg" src="@/assets/index/china_bg1.png" alt="">
              <!-- 江苏：无锡、苏州、常州、镇江、南通、南京、扬州、徐州、连云港、宿迁、淮安、盐城、泰州
              安徽：合肥、蚌埠、芜湖、马鞍山
              湖北：武汉、鄂州、恩施
              陕西：西安
              四川：成都
              湖南：长沙
              甘肃：兰州
              黑龙江：哈尔滨
              上海
              重庆 -->
            </div>

          </el-col>
        </el-row>
      </div>
    </div>
    <div class="contact-us">
      <div class="wrapper text-center">
        <p class="s-title">联系我们，获取尊享服务</p>
        <p class="s-desc">专业的产品介绍，一对一客户服务</p>
        <div class="fabu" style="margin-top: 30px;" @click="goTo('releaseRequire')">
          发布需求
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import chinaMap from "@/assets/china.json";
export default {
  data() {
    return {
      activeName: "first",
      timer: null,
      isMobile: null,
      loadMore: false
    };
  },
  created() {},

  mounted() {
    this.$nextTick(() => {
      this.isMobile = window.isMobile;
      this.initChinaEcharts();
    });
  },
  methods: {
    goTo(url) {
      this.$router.push(`/${url}`);
    },
    initChinaEcharts() {
      echarts.registerMap("china", { geoJSON: chinaMap });
      let chart = echarts.init(document.getElementById("china-echarts"));
      var mapName = "china";
      var geoCoordMap = {
        江苏: [118.767413, 32.041544],
        安徽: [117.283042, 31.86119],
        湖北: [114.298572, 30.584355],
        陕西: [108.948024, 34.263161],
        四川: [104.065735, 30.659462],
        湖南: [112.982279, 28.19409],
        甘肃: [103.823557, 36.058039],
        黑龙江: [126.642464, 45.756967],
        上海: [121.472644, 31.231706],
        重庆: [106.504962, 29.533155]
      };
      var geoList = [
        { name: "江苏" },
        { name: "安徽" },
        { name: "湖北" },
        { name: "陕西" },
        { name: "四川" },
        { name: "湖南" },
        { name: "甘肃" },
        { name: "黑龙江" },
        { name: "上海" },
        { name: "重庆" }
      ];
      var convertData = function(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };
      let options = {
        // backgroundColor: 'rgba(0,0,0,0)',
        geo: {
          show: true,
          map: mapName,
          // aspectScale:1,
          // center:[113.495619,23224029],
          top: 140,
          left: 150,
          zoom: 1.8,
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "rgba(0,0,0,0)",
              borderColor: "rgba(0,0,0,0)",
              borderWidth: 1
            },
            emphasis: {
              show: false,
              areaColor: "rgba(0,0,0,0)"
            }
          }
          // regions: [{ //不显示南海诸岛，的小地图
          //   name: '南海诸岛',
          //   value: 0,
          //   itemStyle: {
          //     normal: {
          //       opacity: 0,
          //       label: {
          //         show: false
          //       }
          //     }
          //   }
          // }]
        },
        series: [
          {
            // 基站塔图
            type: "effectScatter", //气泡效果scatter
            rippleEffect: {
              //气泡效
              brushType: "stroke"
            },
            hoverAnimation: true, //气泡效果end
            zlevel: 6,
            coordinateSystem: "geo",
            symbolSize: 20,
            label: {
              normal: {
                formatter: "{b}",
                position: "top",
                show: false,
                fontSize: 22,
                backgroundColor: "#3181d8", // 背景色
                padding: [10, 15], // 左右间距
                borderRadius: 2, //  圆角
                color: "#fff", //  颜色
                borderWidth: 1,
                borderColor: "#3181d8"
              },
              emphasis: {
                show: true
              }
            },
            rippleEffect: {
              brushType: "stroke"
            },
            data: convertData(geoList)
          }
        ]
      };
      chart.setOption(options);
      // 动态显示tootip
      // chart.dispatchAction({
      //   type: 'highlight',//默认显示江苏的提示框
      //   seriesIndex: 0,//这行不能省
      //   dataIndex: 0
      // });
      var faultByHourIndex = 0; //播放所在下标
      this.timer = setInterval(function() {
        //使得tootip每隔三秒自动显示
        chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: faultByHourIndex - 1
        });
        chart.dispatchAction({
          type: "highlight", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: faultByHourIndex
        });

        // console.log(faultByHourIndex, 'faultByHourIndex')
        faultByHourIndex++;
        // faultRateOption.series[0].data.length 是已报名纵坐标数据的长度
        if (faultByHourIndex > geoList.length) {
          faultByHourIndex = 0;
        }
      }, 2000);
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
</style>
